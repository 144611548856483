import { combineReducers, configureStore } from '@reduxjs/toolkit'
import professionsReducer from './professions'
import qualitiesReducer from './qualities'
import usersReducer from './users'
import commentsReducer from './comments'

const rootReducer = combineReducers({
    qualities: qualitiesReducer,
    professions: professionsReducer,
    users: usersReducer,
    comments: commentsReducer
})

export function createStore() {
    return configureStore({
        reducer: rootReducer
    })
}
